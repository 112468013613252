import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import fontService, { FontWeight } from 'services/FontService';

import { Icon, IconNames, Button } from 'atoms';
import { useDispatch, useSelector } from 'react-redux';
import {
  getTableOpened,
  getWebSocketConnection,
  getSocketDisconnected,
  getSelectedTableData,
} from 'core/selectors';

export const NotAvailablePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tableOpened = useSelector(getTableOpened);
  const webSocketConnection = useSelector(getWebSocketConnection);
  const isSocketDisconnected = useSelector(getSocketDisconnected);
  const selectedTableData = useSelector(getSelectedTableData);

  const onBackToMainClick = useCallback(() => {
    navigate('/', { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (
      tableOpened &&
      webSocketConnection &&
      !isSocketDisconnected &&
      selectedTableData?.table_id
    ) {
      navigate(`/table/${selectedTableData?.table_id}`, { replace: true });
    }
  }, [
    tableOpened,
    webSocketConnection,
    isSocketDisconnected,
    selectedTableData,
    navigate,
    dispatch,
  ]);

  return (
    <div>
      <Header>
        <Text>Dealer User Interface (v.1.3.2)</Text>
      </Header>

      <NoAvailableContainer>
        <Icon name={IconNames.NoAvailablePage} />
        <NoAvailableTitle>This page is not available</NoAvailableTitle>
        <CustomButton onClick={onBackToMainClick} type="button">
          Main Page
        </CustomButton>
      </NoAvailableContainer>
    </div>
  );
};

const NoAvailableContainer = styled.div`
  width: 100%;
  max-width: 1152px;
  min-height: calc(100vh - 345px);
  margin-top: 16px;
  display: flex;
  margin: 16px auto 0;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  background: #121011;
`;

const Text = styled.p`
  width: 100%;
  height: 100%;
  max-width: 1152px;
  ${fontService.text({ size: 10, weight: FontWeight.regular })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const NoAvailableTitle = styled.p`
  ${fontService.text({ size: 24, weight: FontWeight.bold })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  text-transform: uppercase;
`;

const CustomButton = styled(Button)`
  width: 240px;
  text-transform: uppercase;
  background: #BB9370;
  border: 1px solid rgba(187, 147, 112, 0.24);
  &:hover {
    background: #D4BB93;
    border: 1px solid rgba(212, 187, 147, 0.24);
    cursor: pointer;
`;
