import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  openTableSuccessAction,
  changeGameStateAction,
  saveGameDataToStore,
  changeGameStatusAction,
  setCardsStatusToStore,
  setIsJokerCardRound,
  setIsStartRound,
  setJokerCardToStore,
  setBurnCardsAmountAction,
} from 'data/reducers';
import { Notifications, TableOpenedResponse, TableStates } from 'types';
import { gameStatus } from 'constants/common';
import { isEmpty } from 'ramda';

export function* tableOpened({ type: responseType, data }: TableOpenedResponse) {
  try {
    if (responseType === Notifications.TABLE_OPENED) {
      yield put(changeGameStateAction({ tableState: TableStates.TableOpened }));
      yield put(openTableSuccessAction());

      if (data.state && !isEmpty(data.state)) {
        yield put(saveGameDataToStore({ selectedTableData: data.state }));
      }

      yield put(
        setBurnCardsAmountAction({
          burn_card_amount: data.state.burn_card_amount,
          additional_burn_card_mount: data.state.additional_burn_card_mount,
        }),
      );

      const joker = data?.gameState?.joker;
      if (data.state?.joker_high_low && !joker) {
        yield put(changeGameStatusAction({ status: gameStatus.JokerCard }));
        yield put(setCardsStatusToStore(''));
        yield put(setIsStartRound(true));
        yield put(setIsJokerCardRound(false));
        yield put(setJokerCardToStore(joker));
        return;
      }
      if (data.gameState?.table?.joker_high_low && joker) {
        yield put(setJokerCardToStore(joker));
        yield put(changeGameStatusAction({ status: '' }));
        yield put(setCardsStatusToStore(''));
        yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
        yield put(setIsStartRound(false));
        return;
      }

      yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
    }
  } catch (error) {
    Sentry.captureException({ error });
  }
}
