import { TableControls, TableHead, TableStatusInfo } from 'components/molecules';
import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';

// TODO: move gradients into theme const

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0.24) 0%, rgba(18, 16, 17, 0.4) 86.04%),
    #121011;

  ${({ theme: { button } }) => css`
    border-bottom: 1px solid ${button.primary};
  `}
`;

export const TableHeadStyled = styled(TableHead)`
  max-width: 1280px;
  width: 100%;
  padding: 0 64px;
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 444px 675px;
  column-gap: 32px;
  max-width: 1280px;
  width: 100%;
  margin: 32px auto 0;
  padding: 0 64px;
`;

export const ControlsWrapper = styled.div``;

export const TableStatusInfoStyled = styled(TableStatusInfo)``;

export const TableControlsStyled = styled(TableControls)`
  margin-top: 24px;
`;

export const CloseNotificationWrapper = styled.div`
  margin-top: 32px;
`;

export const ViewWrapper = styled.div`
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-radius: 12px;
  overflow: hidden;

  ${({ theme: { background } }) => css`
    border-color: ${background.secondaryDefault};
    background-color: ${background.primaryContrast};
  `}
`;

export const HistoryResult = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  display: flex;
  z-index: 2;
`;
export const ConnectionStatusWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  z-index: 2;
`;

export const CardWrapper = styled.div`
  width: 24px;
  height: 24px;
  &:not(:first-of-type) {
    margin-left: 8px;
  }
`;

export const GameProvider = styled.div`
  padding: 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  background: #121011;
  border-color: ##bb9370;
  color: #d4bb93;
  font-size: 24px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const IconCardMini = styled.div`
  /* height: 100px;
  width: 50px; */

  svg {
    height: 50px;
    width: auto;
  }
`;

export const CardsMini = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 900px;
  margin-bottom: 100px;
`;

export const Line = styled.div`
  border-bottom: 1px solid #bb9370;
  margin: 40px 0;
`;

export const Block = styled.div`
  margin: 30px 0 0 0;
`;

export const WindowBurnCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0.6) 40%, #121011 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WindowBurnCardContent = styled.div<{
  backgroundColor: string;
  borderColor: string;
}>`
  background: #2b2a2b;
  height: fit-content;
  padding: 24px 53px;
  border-radius: 12px;
  transform: translateY(-60px);
  background: ${({ backgroundColor }) => `${backgroundColor}`};
  border: ${({ borderColor }) => `1px solid${borderColor}`};
  text-align: center;
  min-width: 296px;
  width: 296px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

export const WindowShuffleContent = styled(WindowBurnCardContent)`
  padding: 60px 68px;
`;

export const BurnCardText = styled.div`
  ${fontService.getFont({ size: 24, lineHeight: 110, weight: FontWeight.bold })};
  color: #fff7eb;
  text-transform: uppercase;
  width: 80px;
  margin-left: 12px;
`;

export const ShuffleText = styled.div`
  ${fontService.getFont({ size: 24, lineHeight: 40, weight: FontWeight.bold })};
  text-transform: uppercase;
  color: #fff7eb;
  margin-bottom: 32px;
  margin-top: 10px;
`;

export const BurnCardAmount = styled.p`
  ${fontService.getFont({ size: 96, lineHeight: 100, weight: FontWeight.bold })};
  background: -webkit-linear-gradient(
    #ff0505,
    #ff1d05,
    /* #ff4803, */ #ff7502,
    #ffc700,
    #ffc700,
    #ff8800,
    #ff3900,
    #ff0200,
    #ff0200
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  position: relative;
  top: 11px;
  left: 0;
`;

export const BurnCardContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: 28px;
`;

export const ButtonWrapper = styled.div`
  width: 140px;
  margin: auto;
`;

export const BarcodeForm = styled.div`
  display: grid;
  grid-template-columns: auto 148px;
  align-items: end;
  gap: 8px;
  margin-bottom: 100px;

  input {
    padding: 9px 15px;
    border-radius: 8px;
    font-size: 22px;

    ::placeholder {
      font-size: 22px;
    }
  }
`;

export const BarcodeFormButton = styled.div`
  width: 148px;
`;

export const BlockCards = styled.span`
  color: #d4bb93;
`;
