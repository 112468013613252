export enum ACTIONS {
  INIT_APP_REQUEST = 'INIT_APP_REQUEST',
  INIT_APP_SUCCESS = 'INIT_APP_SUCCESS',
  INIT_APP_FAILURE = 'INIT_APP_FAILURE',

  REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST',

  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',

  LOGIN_TO_SOCKET_REQUEST = 'LOGIN_TO_SOCKET_REQUEST',
  LOGIN_TO_SOCKET_SUCCESS = 'LOGIN_TO_SOCKET_SUCCESS',
  LOGIN_TO_SOCKET_FAILURE = 'LOGIN_TO_SOCKET_FAILURE',

  LOGOUT = 'LOGOUT',

  PUT_LOGIN_TO_STORE = 'PUT_LOGIN_TO_STORE',

  GET_TABLES_REQUEST = 'GET_TABLES_REQUEST',
  GET_TABLES_SUCCESS = 'GET_TABLES_SUCCESS',
  GET_TABLES_FAILURE = 'GET_TABLES_FAILURE',

  GET_TABLE_REQUEST = 'GET_TABLE_REQUEST',
  GET_TABLE_SUCCESS = 'GET_TABLE_SUCCESS',
  GET_TABLE_FAILURE = 'GET_TABLE_FAILURE',

  SAVE_TABLES_TO_STORE = 'SAVE_TABLES_TO_STORE',
  SAVE_SELECTED_TABLE_TO_STORE = 'SAVE_SELECTED_TABLE_TO_STORE',
  CLEAR_SELECTED_TABLE_TO_STORE = 'CLEAR_SELECTED_TABLE_TO_STORE',

  SAVE_GAME_TO_STORE = 'SAVE_GAME_TO_STORE',

  CONNECT_TO_WEBSOCKET = 'CONNECT_TO_WEBSOCKET_REQUEST',
  CONNECT_TO_WEBSOCKET_SUCCESS = 'CONNECT_TO_WEBSOCKET_SUCCESS',
  CONNECT_TO_WEBSOCKET_FAILURE = 'CONNECT_TO_WEBSOCKET_FAILURE',

  SOCKET_CONNECTED = 'SOCKET_CONNECTED',
  SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED',

  DISCONNECT_FROM_WEBSOCKET = 'DISCONNECT_FROM_WEBSOCKET',
  CLEAR_SAGA_CHANNELS = 'CLEAR_SAGA_CHANNELS',

  GAME_MACHINE_CONNECTED = 'GAME_MACHINE_CONNECTED',
  GAME_MACHINE_DISCONNECTED = 'GAME_MACHINE_DISCONNECTED',

  CHANGE_TIME_LEFT = 'CHANGE_TIME_LEFT',

  OPEN_TABLE = 'OPEN_TABLE_REQUEST',
  OPEN_TABLE_FAILURE = 'OPEN_TABLE_FAILURE',
  OPEN_TABLE_SUCCESS = 'OPEN_TABLE_SUCCESS',

  TABLE_TAKEN = 'TABLE_TAKEN',
  TABLE_NO_TAKEN = 'TABLE_NO_TAKEN',

  CLOSE_TABLE_VERIFY = 'CLOSE_TABLE_VERIFY_REQUEST',
  CLOSE_TABLE_VERIFY_FAILURE = 'CLOSE_TABLE_VERIFY_FAILURE',
  CLOSE_TABLE_VERIFY_SUCCESS = 'CLOSE_TABLE_VERIFY_SUCCESS',

  CLOSE_TABLE = 'CLOSE_TABLE_REQUEST',
  CLOSE_TABLE_SUCCESS = 'CLOSE_TABLE_SUCCESS',

  CLOSE_TABLE_NOTIFICATION = 'CLOSE_TABLE_NOTIFICATION_REQUEST',

  TABLE_CLOSED = 'TABLE_CLOSED_REQUEST',
  TABLE_CLOSED_SUCCESS = 'TABLE_CLOSED_SUCCESS',

  CHANGE_DEALER = 'CHANGE_DEALER_REQUEST',
  CHANGE_DEALER_FAILURE = 'CHANGE_DEALER_FAILURE',
  CHANGE_DEALER_SUCCESS = 'CHANGE_DEALER_SUCCESS',

  START_ROUND = 'START_ROUND_REQUEST',
  START_ROUND_SUCCESS = 'START_ROUND_SUCCESS',

  CANCEL_ROUND = 'CANCEL_ROUND_REQUEST',
  CANCEL_ROUND_FAILURE = 'CANCEL_ROUND_FAILURE',
  CANCEL_ROUND_SUCCESS = 'CANCEL_ROUND_SUCCESS',

  SAVE_GAME_ID_TO_STORE = 'SAVE_GAME_ID_TO_STORE',
  SAVE_ROUNDS_RESULTS_TO_STORE = 'SAVE_ROUNDS_RESULTS_TO_STORE',
  CHANGE_GAME_STATUS = 'CHANGE_GAME_STATUS',
  CHANGE_GAME_STATE = 'CHANGE_GAME_STATE',

  BETTING_TIME_STARTED = 'BETTING_TIME_STARTED',
  BETTING_TIME_FINISHED = 'BETTING_TIME_FINISHED',

  START_DRAW = 'START_DRAW_REQUEST',
  START_DRAW_SUCCESS = 'START_DRAW_SUCCESS',
  SAVE_ROUND_DATA_TO_STORE = 'SAVE_ROUND_DATA_TO_STORE',
  DRAW_FINISHED_SUCCESS = 'DRAW_FINISHED_SUCCESS',

  CONFIRM_RESULT = 'CONFIRM_RESULT_REQUEST',
  CONFIRM_RESULT_SUCCESS = 'CONFIRM_RESULT_SUCCESS',

  ROUND_FINISHED_SUCCESS = 'ROUND_FINISHED_SUCCESS',

  CHANGE_RESULT_VERIFY = 'CHANGE_RESULT_VERIFY_REQUEST',
  CHANGE_RESULT_VERIFY_FAILURE = 'CHANGE_RESULT_VERIFY_FAILURE',
  CHANGE_RESULT_VERIFY_SUCCESS = 'CHANGE_RESULT_VERIFY_SUCCESS',
  CHANGE_RESULT = 'CHANGE_RESULT_REQUEST',
  CHANGE_RESULT_SUCCESS = 'CHANGE_RESULT_SUCCESS',

  CLEAR_GAME_DATA = 'CLEAR_GAME_DATA',

  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',

  SET_STAGE_JACKPOT = 'SET_STAGE_JACKPOT',
  SET_JACKPOT_WON = 'SET_JACKPOT_WON',
  SET_COUNTDOWN_JACKPOT = 'SET_COUNTDOWN_JACKPOT',
  SET_IS_ACTIVE_COLLECTION_PERIOD = 'SET_IS_ACTIVE_COLLECTION_PERIOD',
  SET_JACKPOT = 'SET_JACKPOT',

  WATCH_DATE_CONTROL_INTERVAL = 'WATCH_DATE_CONTROL_INTERVAL',

  SET_IS_START_ROUND = 'SET_IS_START_ROUND',
  SET_IS_BETTING_TIME = 'SET_IS_BETTING_TIME',
  SET_IS_SCAN_TIME = 'SET_IS_SCAN_TIME',
  SET_IS_ROUND_END = 'SET_IS_ROUND_END',
  SET_IS_JOKER_CARD_ROUND = 'SET_IS_JOKER_CARD_ROUND',
  SET_IS_CANCEL_ROUND = 'SET_IS_CANCEL_ROUND',
  FINISH_ROUND = 'FINISH_ROUND',
  ADD_NEW_CARD = 'ADD_NEW_CARD',
  ADD_CARD_TO_STORE = 'ADD_CARD_TO_STORE',
  CLEAR_CARDS_TO_STORE = 'CLEAR_CARDS_TO_STORE',
  SET_CARDS_TO_STORE = 'SET_CARDS_TO_STORE',
  SET_WINNER_TO_STORE = 'SET_WINNER_TO_STORE',
  SET_IS_BURN_CARD_WINDOW_TO_STORE = 'SET_IS_BURN_CARD_WINDOW_TO_STORE',
  SET_IS_SHUFFLE_CARD_WINDOW_TO_STORE = 'SET_IS_SHUFFLE_CARD_WINDOW_TO_STORE',
  SET_CARDS_STATUS_TO_STORE = 'SET_CARDS_STATUS_TO_STORE',
  SET_HAS_UNDEFINED_CARD = 'SET_HAS_UNDEFINED_CARD',
  SET_HAS_DOUBLE_CARD = 'SET_HAS_DOUBLE_CARD',

  CLOSE_TABLE_ACTION = 'CLOSE_TABLE_ACTION',
  ADDITIONAL_BURN_CARD = 'ADDITIONAL_BURN_CARD',
  ADDITIONAL_SHUFFLE = 'ADDITIONAL_SHUFFLE',
  ADD_NEW_JOKER_CARD = 'ADD_NEW_JOKER_CARD',
  SET_JOKER = 'SET_JOKER',
  SHUFFLE_JOKER = 'SHUFFLE_JOKER',
  SHUFFLE_JOKER_FAILURE = 'SHUFFLE_JOKER_FAILURE',
  SHUFFLE_SUCCESS = 'SHUFFLE_SUCCESS',
  SHUFFLE_VERIFY = 'SHUFFLE_VERIFY',
  SET_BURN_CARDS_AMOUNT = 'SET_BURN_CARDS_AMOUNT',
  SET_ADDITIONAL_BETTING_TIME = 'SET_ADDITIONAL_BETTING_TIME',
}
